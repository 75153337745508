// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$custom-primary: (
  50: #f6f0df,
  100: #e9d9b0,
  200: #dbc07c,
  300: #c79827,
  400: #c79827,
  500: #c08808,
  600: #bd7e01,
  700: #b87100,
  800: #b16400,
  900: #a65000,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: rgba(black, 0.87),
    700: white,
    800: white,
    900: white,
  ),
);

$custom-accent: (
  50: #e3e6e9,
  100: #b9c1c9,
  200: #8a97a5,
  300: #5b6d81,
  400: #384e66,
  500: #152f4b,
  600: #122a44,
  700: #0f233b,
  800: #0c1d33,
  900: #061223,
  A100: #6094ff,
  A200: #2d72ff,
  A400: #0052f9,
  A700: #0049e0,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$mandates-fe-primary: mat.define-palette($custom-primary);
$mandates-fe-accent: mat.define-palette($custom-accent);

// The warn palette is optional (defaults to red).
$mandates-fe-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$mandates-fe-theme: mat.define-light-theme(
  (
    color: (
      primary: $mandates-fe-primary,
      accent: $mandates-fe-accent,
      warn: $mandates-fe-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($mandates-fe-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.user-menu {
  max-width: none !important;
  box-shadow: none;
  border: 2px #efefef solid;
  border-radius: 0 !important;
}

.sidenav-container > .sidenav > .mat-drawer-inner-container {
  display: flex !important;
  flex-direction: column !important;

  mat-form-field.lang-select > div.mat-form-field-wrapper > div.mat-form-field-flex {
    .mat-form-field-outline {
      color: white !important;
    }

    .mat-form-field-outline {
      color: white !important;
    }

    .mat-select-value,
    .mat-select-arrow {
      color: white;
    }

    .mat-form-field-infix {
      padding: 0.5rem 0 1.1em 0;

      mat-select > .mat-select-trigger > .mat-select-arrow-wrapper {
        display: flex;
      }
    }
  }
}

.custom-tabs {
  padding: 0 1.5rem;

  .mat-tab-header {
    margin-bottom: 1.5rem;
  }

  .mat-tab-label {
    font-size: 20px;
    font-weight: 400;
  }

  .mat-tab-label:not(.mat-tab-disabled) {
    color: unset;
    opacity: unset;
  }

  .mat-tab-label.mat-tab-label-active {
    color: #bd7d00;
  }
}

.backdropBackground {
  background-color: rgb(200 200 200 / 50%);
  backdrop-filter: blur(4px);
}

.primary-header-checkbox {
  .mat-checkbox-frame {
    border-color: white;
  }

  &.mat-checkbox-checked,
  &.mat-checkbox-indeterminate {
    .mat-checkbox-background {
      background-color: white;

      svg path {
        stroke: #bd7d00 !important;
      }

      .mat-checkbox-mixedmark {
        background-color: #bd7d00 !important;
      }
    }
  }
}

.accent-header-checkbox {
  .mat-checkbox-frame {
    border-color: white;
  }
}

.header-checkbox {
  .mat-checkbox-frame {
    border-color: white;
  }

  &.mat-checkbox-checked,
  &.mat-checkbox-indeterminate {
    .mat-checkbox-background {
      background-color: white;

      svg path {
        stroke: #808078 !important;
      }

      .mat-checkbox-mixedmark {
        background-color: #808078 !important;
      }
    }
  }
}

.mat-table {
  .mat-header-cell:first-child {
    /* row border */
    border-radius: 0.5rem 0 0 0;
  }

  .mat-header-cell:last-child {
    /* row border */
    border-radius: 0 0.5rem 0 0;
  }
}

.mat-sort-header-arrow {
  color: white;
}

.mat-sort-header-button {
  outline: none !important;
}
